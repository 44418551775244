<template>
  <div class="text">正在登录{{ type }}</div>
</template>

<script>
import { get, Toast, Dialog } from 'lodash-es';
export default {
  async created() {
    let openid = get(this.$route, 'query.openid');
    let unionid = get(this.$route, 'query.unionid');
    // 1-老板 2-吧员 6-未注册 其余游客
    let type = get(this.$route, 'query.type');
    this.type = type;

    if (!openid) {
      this.$router.replace('/nopermission');
    }

    window.sessionStorage.setItem('openid', openid); 
    if (type === '6') {
      this.$router.replace('/refueling/tel?unionid=' + unionid);
    } else {
      this.$router.replace('/refueling/my');     
    }

    /*if (type === '1') {
      this.$router.replace('/position/list');
    } else if (type === '2') {
      this.$router.replace('/admin/tel');
    } else if (type === '3') {
      this.$router.replace('/admin/info');
    } else if (type === '4') {
      this.$router.replace('/nopermission');
    }*/
  },
  data() {
    return {
      type: ''
    };
  }
};
</script>

<style lang="less" scoped>
.text {
  margin-top: 20px;
  text-align: center;
  font-size: 30px;
}
</style>
